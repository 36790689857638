import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  Tooltip,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Toolbar from "rsuite/esm/DatePicker/Toolbar";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { TooltipController } from "bizcharts";
import LinkUtils from "lib/LinkUtils";

class LearningCourseTopicSelectorDrawer extends React.Component {
  state = {
    name: "",
    description: "",
    topics: [],
    selectedTopics: [],
  };

  getTopics() {
    this.setState({
      loadingTopics: true,
    });

    APIV2.getLibraryTopics()
      .then(
        (data) => {
          this.setState({
            topics: data?.data?.topics,
          });
        },
        () => {
          window.alert("Failed to get topics. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingTopics: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open != prevProps.open && this.props.open) {
      this.getTopics();

      if (this.props.selectedTopics) {
        this.setState({
          selectedTopics: this.props.selectedTopics?.length
            ? this.props.selectedTopics?.map((topic) => topic?.topic?._id)
            : null,
        });
      }
    }

    if (this.props.selectedTopics != prevProps.selectedTopics) {
      this.setState({
        selectedTopics: this.props.selectedTopics?.length
          ? this.props.selectedTopics?.map((topic) => topic?.topic?._id)
          : null,
      });
    }
  }

  selectedTopic(topic, selected) {
    if (typeof this.props.onSelect == "function") {
      this.props.onSelect(topic, selected);
    }
  }

  componentDidMount() {
    this.getTopics();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  searchTopics(query) {
    this.setState({
      searchMode: true,
      searching: true,
    });

    APIV2.simpleSearchLibraryTopics(query)
      .then(
        (data) => {
          let topics = data.data?.topics?.length ? data.data?.topics : [];

          let out = [];

          for (let i = 0; i < topics.length; i++) {
            if (!_.findWhere(out, { _id: topics[i]._id })) {
              out.push(topics[i]);
            }
          }

          this.setState({
            searchTopics: out,
          });
        },
        (e) => {
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          searching: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => {
            if (typeof this.props.onClose == "function") {
              this.props.onClose();
            }
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              All Topics
            </h3>
          </Drawer.Header>
          <div
            style={{ position: "sticky", top: 0 }}
            className="p-3 border-bottom"
          >
            {this.state.searchMode ? (
              <Row>
                <Col xs="">
                  <Input
                    name="search"
                    readOnly={true}
                    value={this.state.search}
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                    type="text"
                    placeholder="Search topics..."
                    bsSize="sm"
                  />
                </Col>
                <Col xs="auto" className="pl-0">
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    disabled={this.state.searching}
                    className="btn-icon-only"
                    onClick={() => {
                      this.setState({
                        searchMode: false,
                        search: "",
                        searchTopics: [],
                      });
                    }}
                  >
                    {this.state.searching ? (
                      <Spinner size="sm" />
                    ) : (
                      <i className="mdi mdi-close"></i>
                    )}
                  </Button>
                </Col>
              </Row>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  console.log("SUBMIT", this.state.search);

                  this.searchTopics(this.state.search);
                }}
              >
                <Row>
                  <Col xs="">
                    <Input
                      name="search"
                      disabled={this.state.searching}
                      value={this.state.search}
                      onChange={(e) => {
                        this.setState({ search: e.target.value });
                      }}
                      type="text"
                      placeholder="Search topics..."
                      bsSize="sm"
                    />
                  </Col>
                  <Col xs="auto" className="pl-0">
                    <Button
                      disabled={this.state.searching}
                      size="sm"
                      color="primary"
                      className="btn-icon-only"
                      type="submit"
                    >
                      {this.state.searching ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="mdi mdi-magnify"></i>
                      )}
                    </Button>
                  </Col>
                </Row>
              </form>
            )}
          </div>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {this.state.searchMode ? (
              <>
                {this.state.searching ? <></> : null}
                {!this.state.searching && !this.state.searchTopics?.length ? (
                  <p className="text-center">No topics found.</p>
                ) : null}
                {!this.state.searching && this.state.searchTopics?.length ? (
                  <>
                    {this.state.searchTopics?.map((topic, i) => (
                      <>
                        <div
                          className={`pb-3 ${i != 0 && "pt-3"} ${
                            i != this.state.searchTopics?.length - 1 &&
                            "border-bottom"
                          }`}
                          key={topic?._id}
                        >
                          <Row className="align-items-center">
                            <Col xs="">
                              <h4 className="m-0">
                                {topic?.internal ? (
                                  <Badge color="light" className="border mr-2">
                                    INTERNAL
                                  </Badge>
                                ) : null}
                                {topic?.name}
                              </h4>
                              {topic.description ? (
                                <>
                                  <p
                                    className="small m-0"
                                    style={{ lineHeight: 1.2 }}
                                  >
                                    {topic?.description}
                                  </p>
                                </>
                              ) : null}
                              <p
                                className="small mt-1 mb-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                Created:{" "}
                                {moment(topic?.createdAt).format("MM/DD/YYYY")}
                              </p>
                            </Col>
                            <Col xs="auto">
                              <Button
                                size="sm"
                                disabled={
                                  this.state.selectedTopics?.includes(
                                    topic?._id
                                  ) ||
                                  (this.props.maxSelected > 0 &&
                                    this.state.selectedTopics?.length >=
                                      this.props.maxSelected)
                                }
                                color="primary"
                                outline={
                                  !this.state.selectedTopics?.includes(
                                    topic?._id
                                  )
                                }
                                onClick={() => {
                                  if (
                                    this.state.selectedTopics?.includes(
                                      topic?._id
                                    )
                                  ) {
                                    return;
                                  }

                                  this.selectedTopic(topic, true);
                                }}
                              >
                                {this.state.selectedTopics?.includes(topic?._id)
                                  ? "Added"
                                  : "Add"}
                              </Button>
                              <Button
                                size="sm"
                                color="dark"
                                outline
                                onClick={() => {
                                  LinkUtils.openInNewTab(
                                    `/university/library/topics/${topic?._id}`,
                                    true
                                  );
                                }}
                              >
                                View
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ))}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {this.state.topics?.map((topic, i) => (
                  <>
                    <div
                      className={`pb-3 ${i != 0 && "pt-3"} ${
                        i != this.state.topics?.length - 1 && "border-bottom"
                      }`}
                      key={topic?._id}
                    >
                      <Row className="align-items-center">
                        <Col xs="">
                          <h4 className="m-0">
                            {topic?.internal ? (
                              <Badge color="light" className="border mr-2">
                                INTERNAL
                              </Badge>
                            ) : null}
                            {topic?.name}
                          </h4>
                          {topic.description ? (
                            <>
                              <p
                                className="small m-0"
                                style={{ lineHeight: 1.2 }}
                              >
                                {topic?.description}
                              </p>
                            </>
                          ) : null}
                          <p
                            className="small mt-1 mb-0"
                            style={{ lineHeight: 1.2 }}
                          >
                            Created:{" "}
                            {moment(topic?.createdAt).format("MM/DD/YYYY")}
                          </p>
                        </Col>
                        <Col xs="auto">
                          <Button
                            size="sm"
                            disabled={
                              this.state.selectedTopics?.includes(topic?._id) ||
                              (this.props.maxSelected > 0 &&
                                this.state.selectedTopics?.length >=
                                  this.props.maxSelected)
                            }
                            color="primary"
                            outline={
                              !this.state.selectedTopics?.includes(topic?._id)
                            }
                            onClick={() => {
                              if (
                                this.state.selectedTopics?.includes(topic?._id)
                              ) {
                                return;
                              }

                              this.selectedTopic(topic, true);
                            }}
                          >
                            {this.state.selectedTopics?.includes(topic?._id)
                              ? "Added"
                              : "Add"}
                          </Button>
                          <Button
                            size="sm"
                            color="dark"
                            outline
                            disabled={this.state.deletingTopic == topic?._id}
                            onClick={() => {
                              LinkUtils.openInNewTab(
                                `/university/library/topics/${topic?._id}`,
                                true
                              );
                            }}
                          >
                            View
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </>
                ))}
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(LearningCourseTopicSelectorDrawer);
