import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeaderV2 from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  Drawer,
  Dropdown,
  Popover,
  SelectPicker,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerInvoiceDrawer from "components/Drawers/CustomerInvoiceDrawer";
import ProductionOrderDrawer from "../../components/Drawers/ProductionOrderDrawer";
import LinkUtils from "../../lib/LinkUtils";
import NoteBody from "components/NoteBody";
import swal from "sweetalert";

class ProductionProductOrderListView extends React.Component {
  state = {
    active: {
      id: "All Pending Orders",
      name: "All Pending Orders",
    },
    tabs: [
      {
        name: "All Pending Orders",
        id: "All Pending Orders",
      },
      {
        name: "Order: Draft",
        id: "Order: Draft",
      },
      {
        name: "Order: Submitted",
        id: "Order: Submitted",
      },
      {
        name: "Order: Accepted",
        id: "Order: Accepted",
      },
      {
        name: "Order: Processing",
        id: "Order: Processing",
      },
      {
        name: "Order: In Transit",
        id: "Order: In Transit",
      },
      {
        name: "Order: Received",
        id: "Order: Received",
      },
      {
        name: "Transfer: Unfunded",
        id: "Transfer: Unfunded",
      },
      {
        name: "Transfer: Funded",
        id: "Transfer: Funded",
      },
      {
        name: "Completed Orders",
        id: "Completed Orders",
      },
      {
        name: "All Orders",
        id: "All Orders",
      },
    ],
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingOrders: true,
    orders: [],
    page: 1,
    count: 15,
    filters: null,
  };

  dropdownRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (
      this.props?.match?.params?.storeID != prevProps?.match?.params?.storeID &&
      this.props.match?.params?.storeID
    ) {
      this.loadStore();
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.PRODUCT_ORDER.CREATED, (m) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.PRODUCT_ORDER.DELETED, (m) => {
      this.handleTabChange(this.state.active, true);
    });

    PubSub.subscribe(Event.PRODUCT_ORDER.MODIFIED, (a) => {
      this.handleTabChange(this.state.active, true);

      if (this.state.openOrder?._id == a?._id) {
        this.setState({
          openOrder: a,
        });
      }
    });

    if (this.props.match?.params?.storeID) {
      this.loadStore();
    }
  }

  loadStore() {
    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {
        alert("Unable to load store. Reload and try again.");
      }
    );
  }

  loadOrders(page = 1, count = 15, filters = null, orderBy = null) {
    this.setState({
      loadingOrders: true,
    });

    const f = filters ? JSON.parse(JSON.stringify(filters)) : {};

    //f.storeID = this.props.match?.params?.storeID;

    APIV2.getProductionOrders(
      page,
      count,
      f,
      orderBy
        ? orderBy
        : {
            orderDate: -1,
          }
    )
      .then(
        (data) => {
          if (data && data.data) {
            let orders = [];

            if (data.data.orders.length) {
              orders = data.data.orders;
            }

            this.setState({
              orders,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the orders. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingOrders: false,
        });
      });
  }

  selectOrder(order, selected) {
    let allSelected = this.state.selectedOrders?.length
      ? this.state.selectedOrders
      : [];

    if (!order) {
      return;
    }

    if (selected && !_.findWhere(allSelected, { _id: order?._id })) {
      allSelected.push(order);
    }

    if (!selected) {
      allSelected = _.filter(allSelected, (o) => {
        return o?._id != order?._id;
      });
    }

    this.setState({
      selectedOrders: allSelected,
    });
  }

  orderIsSelected(order) {
    if (!order || !this.state.selectedOrders?.length) {
      return false;
    }

    return _.findWhere(this.state.selectedOrders, { _id: order?._id })
      ? true
      : false;
  }

  selectAllOrders(select) {
    if (!select) {
      this.setState({
        selectedOrders: [],
      });

      return;
    }

    this.setState({
      selectedOrders: JSON.parse(JSON.stringify(this.state.orders)),
    });
  }

  allFromPageSelected() {
    const ids = this.state.orders?.map((o) => {
      return o?._id;
    });

    if (!ids?.length || !this.state.selectedOrders?.length) {
      return false;
    }

    for (let i = 0; i < ids?.length; i++) {
      const id = ids[i];

      if (!_.findWhere(this.state.selectedOrders, { _id: id })) {
        return false;
      }
    }

    return true;
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page, selectedOrders: [] });

    this.loadOrders(page, this.state.count, this.state.filters);
  }

  formatStatus(type, order) {
    let color = "light";
    let classNames = "";

    if (type == "orderStatus") {
      let icon = "mdi-circle-outline";

      if (order?.orderStatus == "SUBMITTED") {
        color = "medium";
      }

      if (order?.orderStatus == "ACCEPTED") {
        color = "info";
      }

      if (order?.orderStatus == "RECEIVED") {
        color = "success";
      }

      if (order?.orderStatus == "COMPLETED") {
        color = "success";

        icon = "mdi-circle";
      }

      if (
        order?.orderStatus == "PROCESSING" ||
        order?.orderStatus == "IN TRANSIT"
      ) {
        color = "primary";
      }

      return (
        <Badge color={color}>
          <i className={`mr-1 mdi ${icon}`}></i>
          {order?.orderStatus}
        </Badge>
      );
    } else if (type == "invoiceStatus") {
      let icon = "mdi-circle-outline";

      if (
        order?.invoiceStatus == "UNPAID" &&
        (order?.orderStatus == "PROCESSING" ||
          order?.orderStatus == "IN TRANSIT" ||
          order?.orderStatus == "RECEIVED" ||
          order?.orderStatus == "COMPLETED")
      ) {
        color = "";
        classNames = "bg-yellow text-dark";
      }

      if (order?.invoiceStatus == "PAID") {
        color = "success";

        icon = "mdi-circle";
      }

      return (
        <Badge color={color} className={classNames}>
          <i className={`mr-1 mdi ${icon}`}></i>
          {order?.invoiceStatus == "PAID" ? "FUNDED" : "UNFUNDED"}
        </Badge>
      );
    }

    return <>--</>;
  }

  orderRow(c, i) {
    return (
      <>
        <tr
          key={i}
          className="cursor-pointer"
          onClick={() => {
            this.setState({
              createDrawer: true,
              openOrder: c,
            });
          }}
        >
          <td
            className="py-2 pl-3 pr-0"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              checked={this.orderIsSelected(c)}
              onChange={(e, v) => {
                this.selectOrder(c, v);
              }}
            ></Checkbox>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark" style={{ fontSize: "12px" }}>
              {c?.store?.name}
            </p>
            <h4 className="mb-0">{c.name}</h4>
            <p className="mb-0" style={{ fontSize: "12px" }}>
              {c?.orderStatus == "DRAFT" || c?.orderStatus == "SUBMITTED" ? (
                <>
                  {c?.orderStatus == "DRAFT" ? "Drafted" : "Submitted"}{" "}
                  {moment(c?.orderDate).format("MM/DD/YYYY hh:mm a")}
                </>
              ) : (
                <>
                  {c?.store?.code}
                  {moment(c?.orderDate).day(5).format("MMDDYY")}
                </>
              )}
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0">{this.formatStatus("orderStatus", c)}</p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0">{this.formatStatus("invoiceStatus", c)}</p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {StringUtils.numberFormat(c?.summary?.lifestyleMealQuantity)}
            </p>
            <p className="m-0" style={{ fontSize: "12px", lineHeight: 1.2 }}>
              {StringUtils.numberFormat(c?.summary?.lifestyleCaseQuantity ?? 0)}
              &nbsp;Case{c?.summary?.lifestyleCaseQuantity != 1 && "s"}
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {StringUtils.numberFormat(c?.summary?.athleteMealQuantity)}
            </p>
            <p className="m-0" style={{ fontSize: "12px", lineHeight: 1.2 }}>
              {StringUtils.numberFormat(c?.summary?.athleteCaseQuantity ?? 0)}
              &nbsp;Case{c?.summary?.athleteCaseQuantity != 1 && "s"}
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {StringUtils.numberFormat(c?.summary?.leanCheatQuantity)}
            </p>
            <p className="m-0" style={{ fontSize: "12px", lineHeight: 1.2 }}>
              {StringUtils.numberFormat(c?.summary?.leanCheatPackQuantity ?? 0)}
              &nbsp;Case{c?.summary?.leanCheatPackQuantity != 1 && "s"}
            </p>
          </td>
          <td className="py-2 px-3">
            <p className="mb-0 text-dark">
              {StringUtils.centsToCurrency(c?.total ?? 0)}
            </p>
          </td>
        </tr>
      </>
    );
  }

  async loadTabCounts(force = false) {
    if (this.state.tabsLoading) {
      return;
    }

    this.setState(
      {
        tabsLoading: true,
      },
      () => {
        let out = this.state.tabs?.map((tab) => {
          if (force || tab?.countLoading === undefined) {
            tab.count = 0;
            tab.countLoading = true;
          }

          return _.clone(tab);
        });

        this.setState({ tabs: out }, async () => {
          let tabs = this.state.tabs?.map((t) => {
            const { filters } = this.getFilters(t);

            return {
              id: t.id,
              collection: "ProductionOrders",
              query: filters,
            };
          });

          try {
            let data = await APIV2.getTabCounts(tabs);

            let out = this.state.tabs?.map((tab) => {
              const res = _.findWhere(data.data?.tabs, { id: tab.id });

              tab.count = res?.count ? res.count : 0;
              tab.countLoading = false;

              return tab;
            });

            this.setState({
              tabs: out,
            });
          } catch (e) {
            console.error(e);
          }

          this.setState({
            tabsLoading: false,
          });
        });
      }
    );
  }

  getFilters(option) {
    let filters = null;

    if (option.id == "All Orders") {
      filters = null;
    } else if (option.id == "All Pending Orders") {
      filters = {
        orderStatus: {
          $in: [
            "DRAFT",
            "SUBMITTED",
            "ACCEPTED",
            "PROCESSING",
            "IN TRANSIT",
            "RECEIVED",
          ],
        },
      };
    } else if (option.id?.includes("Order:")) {
      const status = option.id?.replace("Order: ", "").toUpperCase();

      console.log(status);

      filters = {
        orderStatus: status,
      };
    } else if (option.id?.includes("Transfer:")) {
      let status = option.id?.replace("Transfer: ", "").toUpperCase();

      status = status == "FUNDED" ? "PAID" : "UNPAID";

      filters = {
        invoiceStatus: status,
      };
    } else if (option.id == "Completed Orders") {
      filters = {
        orderStatus: "COMPLETED",
      };
    }

    return { filters };
  }

  handleTabChange(option, force = false) {
    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    const { filters } = this.getFilters(option);

    this.setState({
      filters,
      active: option,
      selectedOrders: [],
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    const orderBy = {
      orderDate: -1,
    };

    this.loadOrders(1, this.state.count, filters, orderBy);
    this.loadTabCounts();
  }

  setOrderStatus(status) {
    this.setState({
      statusSubmitting: true,
    });

    let promises = [];

    for (let i = 0; i < this.state.selectedOrders?.length; i++) {
      promises.push(
        APIV2.modifyProductOrderStatus(
          this.state.selectedOrders[i]?._id,
          status
        )
      );
    }

    Promise.all(promises)
      .then(() => {
        PubSub.publish(Event.PRODUCT_ORDER.MODIFIED, null);
      })
      .finally(() => {
        this.setState({ statusSubmitting: false });
      });
  }

  setInvoiceStatus(status) {
    this.setState({
      statusSubmitting: true,
    });

    let promises = [];

    for (let i = 0; i < this.state.selectedOrders?.length; i++) {
      promises.push(
        APIV2.modifyProductInvoiceStatus(
          this.state.selectedOrders[i]?._id,
          status
        )
      );
    }

    Promise.all(promises)
      .then(() => {
        PubSub.publish(Event.PRODUCT_ORDER.MODIFIED, null);
      })
      .finally(() => {
        this.setState({ statusSubmitting: false });
      });
  }

  selectedQuantity(type, number = true) {
    if (type == "packingSlip") {
      const orders = _.filter(this.state.selectedOrders, (o) => {
        return o?.orderStatus != "DRAFT" && o?.orderStatus != "SUBMITTED";
      });

      return number ? orders?.length : orders;
    } else if (type == "csv") {
      const orders = _.filter(this.state.selectedOrders, (o) => {
        return (
          o?.orderStatus != "DRAFT" &&
          o?.orderStatus != "SUBMITTED" &&
          !o?.fulfillmentReportID
        );
      });

      return number ? orders?.length : orders;
    } else if (type == "csv-summary") {
      const orders = _.filter(this.state.selectedOrders, (o) => {
        return o?.orderStatus != "DRAFT" && o?.orderStatus != "SUBMITTED";
      });

      return number ? orders?.length : orders;
    } else if (type == "palletSheet") {
      const orders = _.filter(this.state.selectedOrders, (o) => {
        return (
          !o?.fulfillmentReportID &&
          o?.orderStatus != "DRAFT" &&
          o?.orderStatus != "SUBMITTED"
        );
      });

      return number ? orders?.length : orders;
    } else if (type == "label") {
      const orders = _.filter(this.state.selectedOrders, (o) => {
        return o?.fulfillmentReportID;
      });

      return number ? orders?.length : orders;
    } else if (type == "boxingList") {
      const orders = _.filter(this.state.selectedOrders, (o) => {
        return o?.fulfillmentReportID;
      });

      return number ? orders?.length : orders;
    }
  }

  downloadFiles(type) {
    const orders = this.selectedQuantity(type, false);

    let orderIDs = orders
      .map((order) => {
        return order?._id;
      })
      .join(",");

    if (type == "csv-summary") {
      LinkUtils.openInNewTab(
        `/storefront-print/product/order-summary/${orderIDs}/csv`,
        true
      );

      return;
    }

    for (let i = 0; i < orders?.length; i++) {
      const order = orders[i];

      if (type == "packingSlip") {
        LinkUtils.openInNewTab(
          `/storefront-print/product/orders/${order?._id}/packing-slip`,
          true
        );
      } else if (type == "csv") {
        LinkUtils.openInNewTab(
          `/storefront-print/product/orders/${order?._id}/csv`,
          true
        );
      } else if (type == "palletSheet") {
        LinkUtils.openInNewTab(
          `/storefront-print/product/orders/${order?._id}/pallet-sheet`,
          true
        );
      } else if (type == "label") {
        LinkUtils.openInNewTab(
          `/storefront-print/${order?.store?._id}/fulfillment/orders/labels?date=${order?.fulfillmentDateString}`,
          true
        );
      } else if (type == "boxingList") {
        LinkUtils.openInNewTab(
          `/storefront-print/${order?.store?._id}/fulfillment/orders/boxing-list/csv?date=${order?.fulfillmentDateString}`,
          true
        );
      }
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeaderV2
            title="Orders"
            showBackButton={false}
            primaryAction={{
              name: <>New</>,
              onClick: () => {
                this.setState({
                  createDrawer: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={this.state.tabs}
                showCount={true}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
            sticky={true}
          />

          <div
            style={{
              marginLeft: -15,
              marginRight: -15,
              paddingLeft: 15,
              paddingRight: 15,
            }}
            className="mt--4 bg-white border-bottom py-3"
          >
            <Row className="align-items-center" style={{ minHeight: 35 }}>
              {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
              <Col xs="" sm="" className="">
                <div>
                  {this.state.result?.start && this.state.result?.end ? (
                    <>
                      Showing{" "}
                      {StringUtils.numberFormat(this.state.result?.start)}-
                      {StringUtils.numberFormat(this.state.result?.end)}
                      {this.state.result?.total ? (
                        <>
                          &nbsp;of{" "}
                          {StringUtils.numberFormat(this.state.result?.total)}{" "}
                          orders
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>---</>
                  )}
                </div>
              </Col>
              <Col xs="auto" sm="auto" className="pl-2 pl-sm-3 text-right">
                {this.state.selectedOrders?.length > 0 && (
                  <>
                    <Whisper
                      ref={this.dropdownRef}
                      trigger="click"
                      placement={"autoVertical"}
                      speaker={
                        <Popover full>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              panel
                              className="py-2 border-bottom font-weight-800 text-uppercase"
                              style={{
                                paddingLeft: 12,
                                paddingRight: 12,
                              }}
                            >
                              <h5 className="m-0">Print &amp; Download</h5>
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                this.state.statusSubmitting ||
                                !this.selectedQuantity("packingSlip")
                              }
                              onClick={() => {
                                this.downloadFiles("packingSlip");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Packing Slips
                              {this.selectedQuantity("packingSlip")
                                ? ` (${this.selectedQuantity(
                                    "packingSlip"
                                  )} Order${
                                    this.selectedQuantity("packingSlip") != 1
                                      ? "s"
                                      : ""
                                  })`
                                : null}
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={
                                this.state.statusSubmitting ||
                                !this.selectedQuantity("palletSheet")
                              }
                              onClick={() => {
                                this.downloadFiles("palletSheet");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Pallet Sheets
                              {this.selectedQuantity("palletSheet")
                                ? ` (${this.selectedQuantity(
                                    "palletSheet"
                                  )} Order${
                                    this.selectedQuantity("palletSheet") != 1
                                      ? "s"
                                      : ""
                                  })`
                                : null}
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                this.state.statusSubmitting ||
                                !this.selectedQuantity("label")
                              }
                              onClick={() => {
                                this.downloadFiles("label");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Labels
                              {this.selectedQuantity("label")
                                ? ` (${this.selectedQuantity("label")} Order${
                                    this.selectedQuantity("label") != 1
                                      ? "s"
                                      : ""
                                  })`
                                : null}
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                this.state.statusSubmitting ||
                                !this.selectedQuantity("boxingList")
                              }
                              onClick={() => {
                                this.downloadFiles("boxingList");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Boxing Lists
                              {this.selectedQuantity("boxingList")
                                ? ` (${this.selectedQuantity(
                                    "boxingList"
                                  )} Order${
                                    this.selectedQuantity("boxingList") != 1
                                      ? "s"
                                      : ""
                                  })`
                                : null}
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                this.state.statusSubmitting ||
                                !this.selectedQuantity("csv")
                              }
                              onClick={() => {
                                this.downloadFiles("csv");

                                this.dropdownRef.current.close();
                              }}
                            >
                              CSV Download
                              {this.selectedQuantity("csv")
                                ? ` (${this.selectedQuantity("csv")} Order${
                                    this.selectedQuantity("csv") != 1 ? "s" : ""
                                  })`
                                : null}
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={
                                this.state.statusSubmitting ||
                                !this.selectedQuantity("csv-summary")
                              }
                              onClick={() => {
                                this.downloadFiles("csv-summary");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Summary Download
                              {this.selectedQuantity("csv-summary")
                                ? ` (${this.selectedQuantity(
                                    "csv-summary"
                                  )} Order${
                                    this.selectedQuantity("csv-summary") != 1
                                      ? "s"
                                      : ""
                                  })`
                                : null}
                            </Dropdown.Item>
                            <Dropdown.Item
                              panel
                              className="py-2 border-bottom font-weight-800 text-uppercase"
                              style={{
                                paddingLeft: 12,
                                paddingRight: 12,
                              }}
                            >
                              <h5 className="m-0">Order Actions</h5>
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.noteSubmitting}
                              onClick={() => {
                                this.setState({
                                  noteDrawer: true,
                                });

                                this.dropdownRef.current.close();
                              }}
                            >
                              Add New Note
                            </Dropdown.Item>

                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setOrderStatus("DRAFT");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Draft
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setOrderStatus("SUBMITTED");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Submitted
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setOrderStatus("ACCEPTED");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Accepted
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setOrderStatus("PROCESSING");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Processing
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setOrderStatus("IN TRANSIT");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As In Transit
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setOrderStatus("RECEIVED");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Received
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setOrderStatus("COMPLETED");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Completed
                            </Dropdown.Item>

                            <Dropdown.Item
                              panel
                              className="py-2 border-bottom font-weight-800 text-uppercase"
                              style={{
                                paddingLeft: 12,
                                paddingRight: 12,
                              }}
                            >
                              <h5 className="m-0">Transfer Status</h5>
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setInvoiceStatus("UNPAID");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Unpaid
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={this.state.statusSubmitting}
                              onClick={() => {
                                this.setInvoiceStatus("PAID");

                                this.dropdownRef.current.close();
                              }}
                            >
                              Mark All As Paid
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Popover>
                      }
                    >
                      <Button
                        size="sm"
                        color="primary"
                        disabled={this.state.statusSubmitting}
                      >
                        <span style={{ position: "relative", top: -2 }}>
                          {this.state.statusSubmitting ? (
                            <>
                              <Spinner size="sm"></Spinner>
                            </>
                          ) : (
                            <>
                              {StringUtils.numberFormat(
                                this.state.selectedOrders?.length
                              )}{" "}
                              Selected
                            </>
                          )}
                        </span>
                        <i
                          className="mdi mdi-chevron-down"
                          style={{
                            fontSize: 18,
                            lineHeight: 1,
                            position: "relative",
                            top: 1,
                          }}
                        ></i>
                      </Button>
                    </Whisper>
                  </>
                )}

                <Button
                  size="sm"
                  color="secondary"
                  className="btn-icon-only"
                  outline
                  disabled={
                    this.state.page == 1 || !this.state.pagination?.hasPrevious
                  }
                  onClick={() => {
                    this.incrementPage(-1);
                  }}
                >
                  <i
                    className="mdi mdi-chevron-left"
                    style={{ fontSize: "18px" }}
                  ></i>
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  disabled={!this.state.pagination?.hasNext}
                  className="btn-icon-only"
                  outline
                  onClick={() => {
                    this.incrementPage(1);
                  }}
                >
                  <i
                    className="mdi mdi-chevron-right"
                    style={{ fontSize: "18px" }}
                  ></i>
                </Button>
              </Col>
            </Row>
          </div>

          <div
            style={{ marginRight: -15, marginLeft: -15 }}
            className="bg-white border-bottom"
          >
            <Table
              className="align-items-center table-flush table-hover mb-0"
              responsive
            >
              <thead className="thead-light">
                <tr>
                  <th style={{ width: 40 }} className="py-2 pl-3 pr-0">
                    <Checkbox
                      checked={this.allFromPageSelected()}
                      indeterminate={
                        !this.allFromPageSelected() &&
                        this.state.selectedOrders?.length
                      }
                      disabled={!this.state.orders?.length}
                      onChange={(c, v) => {
                        this.selectAllOrders(v);
                      }}
                    ></Checkbox>
                  </th>
                  <th className="text-dark px-3">Order</th>

                  <th className="text-dark px-3">Order Status</th>
                  <th className="text-dark px-3">Transfer Status</th>
                  <th className="text-dark px-3">Lifestyle Meals</th>
                  <th className="text-dark px-3">Athlete Meals</th>
                  <th className="text-dark px-3">Lean Cheats</th>
                  <th className="text-dark px-3">Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.loadingOrders || this.state.searchLoading ? (
                  <>
                    <tr>
                      <td
                        colspan={5}
                        className="text-center p-3"
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          background: "rgba(255, 255, 255, 0.6)",
                        }}
                      >
                        <Spinner
                          style={{ position: "relative", top: "15vh" }}
                          color="medium"
                          size=""
                        ></Spinner>
                      </td>
                    </tr>
                  </>
                ) : null}
                {!this.state.orders?.length && !this.state.loadingOrders ? (
                  <tr>
                    <td colspan={7}>
                      <div className="py-2">
                        <Row>
                          <Col>Get started by creating your first order.</Col>
                        </Row>
                      </div>
                    </td>
                  </tr>
                ) : null}
                {this.state.orders?.map((c, i) => {
                  return this.orderRow(c, i);
                })}
              </tbody>
            </Table>
          </div>
        </Container>
        <ProductionOrderDrawer
          open={this.state.createDrawer}
          createMode={!this.state.openOrder}
          productionMode={true}
          order={this.state.openOrder}
          onClose={() => {
            this.setState({
              createDrawer: false,
              openOrder: null,
            });
          }}
        ></ProductionOrderDrawer>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.noteDrawer}
          onClose={() => {
            this.setState({
              noteDrawer: false,
              note: "",
            });
          }}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              Add Note To Orders
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            <NoteBody
              productOrders={this.state.selectedOrders}
              onSubmit={() => {
                this.setState({
                  notesSubmitting: true,
                });
              }}
              onSave={(note) => {
                this.setState({
                  notesSubmitting: false,
                  noteDrawer: false,
                });

                swal({
                  title: "Note Added To Orders",
                  text: "Your note has been successfully added to the orders.",
                  icon: "success",
                });
              }}
              forceEdit={true}
            ></NoteBody>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default ProductionProductOrderListView;
