import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminFooter from "components/Footers/AdminFooter.js";

import routes from "routes.js";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import User from "lib/Model/User";
import PubSub from "lib/PubSub";
import API from "lib/API";
import APIV2 from "lib/APIV2";
import AdminNavbarV2 from "components/Navbars/AdminNavbarV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import StoreList from "views/examples/StoreList";
import MealList from "views/examples/MealList";
import MenuList from "views/examples/MenuList";
import BillingAccountList from "views/examples/BillingAccountList";
import ProductGroupList from "views/examples/ProductGroupList";
import SignupLinkCreatorView from "views/examples/SignupLinkCreatorView";
import StoreView from "views/examples/StoreView";
import MealView from "views/examples/MealView";
import routesv2 from "routesv2";
import MealCreateView from "views/examples/MealCreateView";
import MealCategoryList from "views/examples/MealCategoryList";
import MealAllergenList from "views/examples/MealAllergenList";
import { Capacitor } from "@capacitor/core";
import FulfillmentSummary from "views/reports/FulfillmentSummary";
import GlobalFulfillmentProjectionView from "views/reports/GlobalFulfillmentProjectionView";
import SalesSummary from "views/reports/SalesSummary";
import ScorecardDashboard from "views/reports/ScorecardDashboard";
import MonthlyBusinessReviewDashboard from "views/reports/MonthlyBusinessReviewDashboard";
import FulfillmentDashboard from "views/reports/FulfillmentDashboard";
import FranLeadDashboard from "views/reports/FranLeadDashboard";
import MarketingCampaignDashboard from "views/reports/MarketingCampaignDashboard";
import StoreGrowthDashboard from "views/reports/StoreGrowthDashboard";

class Reports extends React.Component {
  componentDidUpdate(e) {
    if (e.history?.location?.state?.noScroll) {
      return;
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  componentWillMount() {
    if (Capacitor.getPlatform() == "ios") {
      document.body.className += " ios-padding";
    }

    if (LocalStorage.get(Constant.CACHE.TOKEN)) {
      API.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2.setAuthToken(LocalStorage.get(Constant.CACHE.TOKEN));
      APIV2._wsConnect();
    }

    if (LocalStorage.get(Constant.CACHE.USER)) {
      window[Constant.CACHE.USER] = User._fromData(
        LocalStorage.get(Constant.CACHE.USER)
      );
      PubSub.publish(Event.USER_LOADED, window[Constant.CACHE.USER]);
    }

    APIV2.getAccount().then(
      (data) => {
        if (data && data.data && data.data.user) {
          data.data.user.id = data.data.user._id;

          let user = data.data.user;

          window[Constant.CACHE.USER] = user;
          LocalStorage.set(Constant.CACHE.USER, user);

          console.log("fetched user");

          PubSub.publish(Event.USER_LOADED, user);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  render() {
    if (!LocalStorage.get(Constant.CACHE.TOKEN)) {
      this.props.history.push("/auth");
    }

    return (
      <>
        <SidebarV2
          {...this.props}
          routes={routesv2.reportRoutes}
          basePath={"/reports"}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbarV2
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            disableSearch={true}
          />
          <Switch>
            {/*<Route path="/reports/home" component={StoreList} exact />*/}
            <Route
              path="/reports/scorecard"
              component={ScorecardDashboard}
              exact
            />
            <Route
              path="/reports/business-review"
              component={MonthlyBusinessReviewDashboard}
              exact
            />
            <Route path="/reports/sales" component={SalesSummary} exact />
            <Route
              path="/reports/franleads"
              component={FranLeadDashboard}
              exact
            />
            <Route
              path="/reports/fulfillment"
              component={FulfillmentDashboard}
              exact
            />
            <Route
              path="/reports/fulfillment/detail"
              component={FulfillmentSummary}
              exact
            />
            <Route
              path="/reports/fulfillment/projections"
              component={GlobalFulfillmentProjectionView}
              exact
            />
            <Route
              path="/reports/marketing-campaigns"
              component={MarketingCampaignDashboard}
              exact
            />
            <Route
              path="/reports/store-growth"
              component={StoreGrowthDashboard}
              exact
            />

            <Redirect from="*" to="/reports/scorecard" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Reports;
