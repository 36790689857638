import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  InputGroup,
  InputNumber,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import StorefrontModifyCouponDrawer from "components/Drawers/StorefrontModifyCouponDrawer";
import StorefrontCreateCouponDrawer from "components/Drawers/StorefrontCreateCouponDrawer";

class StorefrontMealInventoryView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  loadMeals() {
    this.setState({
      meals: [],

      loadingMeals: true,
    });

    APIV2.getMeals(true)
      .then(
        (data) => {
          if (data?.data?.meals) {
            //console.log(data.data.meals);

            this.setState({
              meals: data.data.meals,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the meals. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingMeals: false,
        });
      });
  }

  getLatestInventory() {
    APIV2.getLatestInventoryForStore(this.props.match.params.storeID).then(
      (data) => {
        //console.log(data.data);

        this.setState({
          inventory: data.data.inventory ?? {},
          originalInventory: JSON.parse(JSON.stringify(data.data.inventory)),
        });
      },
      (e) => {
        console.error(e);
        this.setState({
          inventory: {},
          originalInventory: {},
        });
      }
    );
  }

  getLastManualAudit() {
    APIV2.getInventory(
      this.props.match.params.storeID,
      {
        type: "Manual Audit",
      },
      { createdAt: -1 },
      1
    ).then(
      (data) => {
        //console.log(data.data);

        this.setState({
          manualAudit: data.data.inventory?.length
            ? data?.data?.inventory[0]
            : null,
        });
      },
      (e) => {}
    );
  }

  getIncomingInventory() {
    this.setState({
      incomingLoading: true,
    });

    APIV2.getIncomingInventoryForStore(this.props.match.params.storeID)
      .then(
        (data) => {
          //console.log(data.data);

          this.setState({
            incomingInventory: data.data.inventory ?? {},
          });
        },
        (e) => {
          console.error(e);
          this.setState({
            incomingInventory: {},
          });
        }
      )
      .finally(() => {
        this.setState({
          incomingLoading: false,
        });
      });
  }

  loadStore() {
    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {
        alert("Unable to load store settings. Reload and try again.");
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.match?.params?.storeID !=
        this?.props?.match?.params?.storeID &&
      this.props?.match?.params?.storeID
    ) {
      this.loadStore();
      this.loadMeals();
      this.getLatestInventory();
      this.getIncomingInventory();
      this.getLastManualAudit();
    }
  }

  componentDidMount() {
    if (this.props?.match?.params?.storeID) {
      this.loadMeals();
      this.loadStore();
      this.getLatestInventory();
      this.getIncomingInventory();
      this.getLastManualAudit();
    }
  }

  isOffMenuMeal(store, meal) {
    if (!store || !meal) {
      return false;
    }

    let onCurrentMenu = _.find(
      store?.menus?.meals[meal?.planTypeID]?.active?.meals,
      (m) => {
        return m?._id == meal?._id && !m?.retired;
      }
    )
      ? true
      : false;

    let onSwapList = false;

    if (store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition) {
      onSwapList = _.findWhere(
        store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
          ?.retiringMealSwaps,
        { to: meal?._id?.toString() }
      )
        ? true
        : false;
    }

    if (!onCurrentMenu && !onSwapList) {
      return true;
    }

    return false;
  }

  isRetiringMeal(store, meal) {
    if (!store || !meal) {
      return false;
    }

    if (meal?.retired) {
      return true;
    }

    if (!store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition) {
      return false;
    }

    let transitionDate = moment(
      store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
        .transitionDate
    ).toDate();

    if (moment().add(4, "weeks").toDate() < transitionDate) {
      return false;
    }

    let onSwapList = _.findWhere(
      store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
        ?.retiringMealSwaps,
      { from: meal?._id?.toString() }
    )
      ? true
      : false;

    return onSwapList;
  }

  getMenuTransitionDate(store, meal) {
    if (!store || !meal) {
      return "(unknown)";
    }

    if (
      !store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
        ?.transitionDate
    ) {
      return "(unknown)";
    }

    let mom = moment(
      store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
        .transitionDate
    );

    return mom.format("MMM DD, YYYY");
  }

  getMenuTransitionReplacement(store, meal) {
    if (!store || !meal) {
      return { name: "(unknown)" };
    }

    if (!store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition) {
      return { name: "(unknown)" };
    }

    let onSwapList = _.findWhere(
      store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
        ?.retiringMealSwaps,
      { from: meal?._id?.toString() }
    );

    return onSwapList?.toMeal ? onSwapList?.toMeal : { name: "(unknown)" };
  }

  isUpcomingMeal(store, meal) {
    if (!store || !meal) {
      return false;
    }

    if (!store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition) {
      return false;
    }

    let transitionDate = moment(
      store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
        .transitionDate
    ).toDate();

    if (moment().add(3, "weeks").toDate() < transitionDate) {
      return false;
    }

    let onSwapList = _.findWhere(
      store?.menus?.meals[meal?.planTypeID]?.active?.menuTransition
        ?.retiringMealSwaps,
      { to: meal?._id?.toString() }
    )
      ? true
      : false;

    let onMenu = _.findWhere(
      store?.menus?.meals[meal?.planTypeID]?.active?.meals,
      { _id: meal?._id }
    )
      ? true
      : false;

    return onSwapList && !onMenu;
  }

  save() {
    this.setState({
      submitting: true,
    });

    let payload = {
      items: this.state.inventory?.items,
      type: "Manual Audit",
      name: `Manual Inventory Audit: ${moment().format(
        "MM-DD-YYYY hh:mm:ss a"
      )}`,
    };

    APIV2.createInventoryForStore(this.props.match?.params?.storeID, payload)
      .then(
        (data) => {
          let inventory = data.data.inventory;

          this.setState({
            modified: false,
            inventory,
            originalInventory: JSON.parse(JSON.stringify(inventory)),
          });
        },
        (e) => {
          console.error(e);
          window.alert(
            `Unable to save inventory: ${
              e?.response?.message ?? "Unknown error occurred."
            }`
          );
        }
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  removeOffMenuMeal(meal) {
    let inventory = this.state.inventory;

    if (inventory?.items?.hasOwnProperty(meal?._id)) {
      delete inventory?.items[meal?._id];
    }

    this.setState({
      inventory,
      modified: true,
    });
  }

  filterStockedMeals(store, categoryName, meals, inventory, onMenu) {
    if (!store || !meals?.length) {
      return [];
    }

    let filteredMeals = [];

    if (categoryName == "Lifestyle") {
      filteredMeals = _.filter(meals, (meal) => {
        return (
          meal?.planType?.name == "Lifestyle" &&
          !_.findWhere(meal.categories, { name: "Lean Cheats" })
        );
      });

      if (onMenu) {
        filteredMeals = _.filter(filteredMeals, (meal) => {
          return (
            _.findWhere(
              store?.menus?.meals["613bdb37164e9569b55f38d0"]?.active?.meals,
              {
                _id: meal?._id,
              }
            ) ||
            (inventory?.items?.hasOwnProperty(meal?._id) &&
              inventory?.items[meal?._id] != 0 &&
              !this.state.modified) ||
            _.findWhere(
              store?.menus?.meals["613bdb37164e9569b55f38d0"]?.active
                ?.menuTransition?.retiringMealSwaps,
              { to: meal?._id }
            )
          );
        });
      } else {
        filteredMeals = _.filter(filteredMeals, (meal) => {
          return !_.findWhere(
            store?.menus?.meals["613bdb37164e9569b55f38d0"]?.active?.meals,
            {
              _id: meal?._id,
            }
          );
        });
      }
    } else if (categoryName == "Athlete") {
      filteredMeals = _.filter(meals, (meal) => {
        return (
          meal?.planType?.name == "Athlete" &&
          !_.findWhere(meal.categories, { name: "Lean Cheats" })
        );
      });

      if (onMenu) {
        filteredMeals = _.filter(filteredMeals, (meal) => {
          return (
            _.findWhere(
              store?.menus?.meals["613bddd9970e86ceef4dd8fc"]?.active?.meals,
              {
                _id: meal?._id,
              }
            ) ||
            (inventory?.items?.hasOwnProperty(meal?._id) &&
              inventory?.items[meal?._id] != 0 &&
              !this.state.modified) ||
            _.findWhere(
              store?.menus?.meals["613bddd9970e86ceef4dd8fc"]?.active
                ?.menuTransition?.retiringMealSwaps,
              { to: meal?._id }
            )
          );
        });
      } else {
        filteredMeals = _.filter(filteredMeals, (meal) => {
          return !_.findWhere(
            store?.menus?.meals["613bddd9970e86ceef4dd8fc"]?.active?.meals,
            {
              _id: meal?._id,
            }
          );
        });
      }
    } else if (categoryName == "Lean Cheats") {
      filteredMeals = _.filter(meals, (meal) => {
        return (
          meal?.planType?.name == "Lifestyle" &&
          _.findWhere(meal.categories, { name: "Lean Cheats" })
        );
      });

      if (onMenu) {
        filteredMeals = _.filter(filteredMeals, (meal) => {
          return (
            _.findWhere(
              store?.menus?.meals["613bdb37164e9569b55f38d0"]?.active?.meals,
              {
                _id: meal?._id,
              }
            ) ||
            (inventory?.items?.hasOwnProperty(meal?._id) &&
              inventory?.items[meal?._id] != 0 &&
              !this.state.modified) ||
            _.findWhere(
              store?.menus?.meals["613bdb37164e9569b55f38d0"]?.active
                ?.menuTransition?.retiringMealSwaps,
              { to: meal?._id }
            ) ||
            _.findWhere(
              store?.menus?.meals["613bddd9970e86ceef4dd8fc"]?.active
                ?.menuTransition?.retiringMealSwaps,
              { to: meal?._id }
            )
          );
        });
      } else {
        filteredMeals = _.filter(filteredMeals, (meal) => {
          return !_.findWhere(
            store?.menus?.meals["613bdb37164e9569b55f38d0"]?.active?.meals,
            {
              _id: meal?._id,
            }
          );
        });
      }
    }

    filteredMeals = _.sortBy(filteredMeals, "name");

    if (categoryName == "Lean Cheats") {
      console.log(filteredMeals); //debug
    }

    return filteredMeals;
  }

  getInventoryValue(meal) {
    if (!this.state.inventory || !meal) {
      return null;
    }

    if (this.state.inventory?.items?.hasOwnProperty(meal?._id)) {
      return this.state.inventory.items[meal?._id];
    }

    return null;
  }

  setInventoryValue(meal, v) {
    if (!this.state.inventory || !meal) {
      return;
    }

    let inventory = this.state.inventory;

    if (!inventory.items) {
      inventory.items = {};
    }

    inventory.items[meal?._id] = v;

    this.setState({
      inventory,
      modified: true,
    });
  }

  getInventorySummary(store, category, inventory) {
    console.log(category); //debug

    let meals = this.filterStockedMeals(
      store,
      category,
      this.state.meals,
      inventory,
      true
    );

    let mealCount = meals?.length ? meals?.length : 0;

    let stocked = 0;

    if (inventory?.items) {
      const keys = _.keys(inventory?.items);

      for (let i = 0; i < keys?.length; i++) {
        if (_.findWhere(meals, { _id: keys[i] })) {
          stocked += inventory.items[keys[i]];
        }
      }
    }

    let incoming = 0;

    if (this.state.incomingInventory) {
      const keys = _.keys(this.state.incomingInventory);

      for (let i = 0; i < keys?.length; i++) {
        if (_.findWhere(meals, { _id: keys[i] })) {
          incoming += this.state.incomingInventory[keys[i]].quantity;
        }
      }
    }

    return `${mealCount?.toString()} ${
      category == "Lean Cheats" ? "cheats" : "meals"
    }, ${StringUtils.numberFormat(stocked)} in stock${
      incoming > 0 ? `, ${StringUtils.numberFormat(incoming)} incoming` : ""
    }`;
  }

  formatStatus(order) {
    let color = "light";

    let icon = "mdi-circle-outline";

    if (order?.orderStatus == "SUBMITTED") {
      color = "medium";
    }

    if (order?.orderStatus == "ACCEPTED") {
      color = "info";
    }

    if (order?.orderStatus == "RECEIVED") {
      color = "success";
    }

    if (order?.orderStatus == "COMPLETED") {
      color = "success";

      icon = "mdi-circle";
    }

    if (
      order?.orderStatus == "PROCESSING" ||
      order?.orderStatus == "IN TRANSIT"
    ) {
      color = "primary";
    }

    return (
      <Badge color={color}>
        <i className={`mr-1 mdi ${icon}`}></i>
        {order?.orderStatus}
      </Badge>
    );

    return <>--</>;
  }

  getIncomingInventoryForMeal(meal) {
    console.log(meal); //debug

    if (this.state.incomingLoading) {
      return <Spinner size="sm" color="medium"></Spinner>;
    }

    if (!this.state.incomingInventory) {
      return "--";
    }

    if (
      !this.state.incomingInventory.hasOwnProperty(meal?._id) ||
      !this.state.incomingInventory[meal?._id]?.quantity
    ) {
      return "--";
    }

    const orders = this.state.incomingInventory[meal?._id].orders;

    console.log(orders);

    return (
      <>
        <div className="text-center">
          <Whisper
            enterable
            speaker={
              <Popover
                className="p-0"
                style={{ minWidth: 280, maxWidth: 350 }}
                full
              >
                <div>
                  <div className="p-2 border-bottom">
                    <h4 className="m-0 text-dark">Incoming Orders</h4>
                  </div>
                  {orders?.map((order, i) => (
                    <div
                      className={`p-2 ${
                        i != orders?.length - 1 && "border-bottom"
                      }`}
                      key={order?._id}
                    >
                      <Row className="align-items-center">
                        <Col xs="12" sm="" style={{ maxWidth: 250 }}>
                          <h5 className="text-dark mb-0">{order?.name}</h5>
                          <p
                            className="m-0"
                            style={{ fontSize: 11, lineHeight: 1.1 }}
                          >
                            {moment(order?.orderDate).format(
                              "MM/DD/YYYY hh:mm a"
                            )}
                          </p>
                        </Col>
                        <Col xs="6" sm="auto">
                          <p className="m-0 text-dark">
                            {StringUtils.numberFormat(order?.quantity)}
                          </p>
                        </Col>
                        <Col xs="6" sm="auto">
                          <p className="m-0 text-dark">
                            {this.formatStatus(order)}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              </Popover>
            }
            trigger="hover"
            delayOpen={400}
            placement="auto"
          >
            <div className="d-inline-block">
              <p className="m-0 text-dark">
                {StringUtils.numberFormat(
                  this.state.incomingInventory[meal?._id].quantity
                )}
              </p>
            </div>
          </Whisper>
        </div>
      </>
    );
  }

  inventoryRow(c, onMenu = true) {
    return (
      <Row className="align-items-center">
        <Col xs="12" sm="12" md="4" className="text-center text-md-left">
          <h4 className={`mb-0 text-capitalize d-flex align-items-center`}>
            {this.isRetiringMeal(this.state.store, c) ? (
              <>
                <Whisper
                  trigger="hover"
                  placement="auto"
                  delayOpen={400}
                  speaker={
                    <Tooltip>
                      <p style={{ lineHeight: 1.3 }} className="m-0 text-white">
                        This meal is becoming seasonal on{" "}
                        <span className="font-weight-bold">
                          {this.getMenuTransitionDate(this.state.store, c)}
                        </span>{" "}
                        and will be replaced with{" "}
                        <span className="font-weight-bold">
                          {
                            this.getMenuTransitionReplacement(
                              this.state.store,
                              c
                            )?.name
                          }
                        </span>
                      </p>
                    </Tooltip>
                  }
                >
                  <div className="d-inline-block">
                    <i
                      className="mdi mdi-checkbox-blank-off-outline pr-2 text-warning"
                      style={{ fontSize: 20 }}
                    ></i>
                  </div>
                </Whisper>
              </>
            ) : null}
            {this.isOffMenuMeal(this.state.store, c) &&
            !this.isRetiringMeal(this.state.store, c) ? (
              <>
                <Whisper
                  trigger="hover"
                  placement="auto"
                  delayOpen={400}
                  speaker={
                    <Tooltip>
                      <p style={{ lineHeight: 1.3 }} className="m-0 text-white">
                        This meal is a seasonal meal and is no longer available
                        in future Product Orders.
                      </p>
                    </Tooltip>
                  }
                >
                  <div className="d-inline-block">
                    <i
                      className="mdi mdi-checkbox-blank-off-outline pr-2 text-warning"
                      style={{ fontSize: 20 }}
                    ></i>
                  </div>
                </Whisper>
              </>
            ) : null}
            {this.isUpcomingMeal(this.state.store, c) ? (
              <>
                <Whisper
                  trigger="hover"
                  placement="auto"
                  delayOpen={400}
                  speaker={
                    <Tooltip>
                      <p style={{ lineHeight: 1.3 }} className="m-0">
                        This new meal will be available to clients starting:{" "}
                        <span className="font-weight-bold">
                          {this.getMenuTransitionDate(this.state.store, c)}
                        </span>
                      </p>
                    </Tooltip>
                  }
                >
                  <div className="d-inline-block">
                    <i
                      className="mdi mdi-plus-circle pr-2 text-success"
                      style={{ fontSize: 20 }}
                    ></i>
                  </div>
                </Whisper>
              </>
            ) : null}
            {c.name}
          </h4>
          {this.isOffMenuMeal(this.state.store, c) ||
          this.isRetiringMeal(this.state.store, c) ? (
            <>
              <p className="small text-dark m-0">
                {this.isRetiringMeal(this.state.store, c) ? (
                  <>
                    Leaving menu on{" "}
                    {this.getMenuTransitionDate(this.state.store, c)}
                  </>
                ) : (
                  <>Off-Menu Meal</>
                )}
              </p>
            </>
          ) : null}
        </Col>
        <Col xs="12" sm="6" md="4" className="mt-3 mt-md-0 text-md-center">
          {this.getIncomingInventoryForMeal(c)}
        </Col>
        <Col xs="12" sm="6" md="4" className="mt-3 mt-md-0 ">
          <Row className="align-items-center">
            <Col xs="">
              <InputGroup>
                <InputGroup.Button
                  tabindex="-1"
                  size="sm"
                  disabled={this.state.submitting}
                  onClick={(v) => {
                    this.setInventoryValue(
                      c,
                      this.getInventoryValue(c)
                        ? this.getInventoryValue(c) - 1
                        : 1
                    );
                  }}
                >
                  <i className="mdi mdi-minus"></i>
                </InputGroup.Button>
                <InputNumber
                  value={this.getInventoryValue(c)}
                  size="sm"
                  onChange={(v) => {
                    this.setInventoryValue(c, v ? parseInt(v) : 0);
                  }}
                  disabled={this.state.submitting}
                  className="rs-input-number-noarrows"
                />
                <InputGroup.Button
                  tabindex="-1"
                  size="sm"
                  onClick={() => {
                    this.setInventoryValue(
                      c,
                      this.getInventoryValue(c)
                        ? this.getInventoryValue(c) + 1
                        : 1
                    );
                  }}
                  disabled={this.state.submitting}
                >
                  <i className="mdi mdi-plus"></i>
                </InputGroup.Button>
              </InputGroup>
            </Col>

            {this.getInventoryValue(c) !== null &&
              this.isOffMenuMeal(this.state.store, c) && (
                <Col xs="auto" className="pl-0">
                  {" "}
                  <Whisper
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        Remove Seasonal Meal From Inventory Report
                      </Tooltip>
                    }
                    delayOpen={400}
                    placement="auto"
                  >
                    <Button
                      onClick={() => {
                        this.removeOffMenuMeal(c);
                      }}
                      size="sm"
                      outline
                      color="danger"
                      className="btn-icon-only"
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  </Whisper>
                </Col>
              )}
          </Row>
        </Col>
      </Row>
    );
  }

  header(name) {
    return (
      <>
        <CardHeader
          className="p-0 bg-white"
          style={{ position: "sticky", top: "80px", zIndex: 3 }}
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col xs="12" sm="">
                <h3 className="m-0">{name}</h3>
              </Col>
              <Col className="mt-1 mt-sm-0" xs="12" sm="auto">
                {this.getInventorySummary(
                  this.state.store,
                  name?.replace(" Meals", ""),
                  this.state.inventory
                )}
              </Col>
            </Row>
          </div>
          <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
            <Row className="align-items-center">
              <Col xs="12" sm="12" md="4" className="text-center text-md-left">
                <h4 className="mb-0">Meal</h4>
              </Col>
              <Col
                xs="12"
                sm="6"
                md="4"
                className="d-none d-sm-block text-md-center"
              >
                <Whisper
                  speaker={
                    <Tooltip>
                      Quantity arriving in an upcoming order you've placed
                    </Tooltip>
                  }
                  placement="autoVertical"
                  delayOpen={400}
                  trigger="hover"
                >
                  <h4 className="mb-0 cursor-pointer">
                    Incoming
                    <i
                      className="mdi mdi-information-outline pl-1"
                      style={{ position: "relative", top: 1 }}
                    ></i>
                  </h4>
                </Whisper>
              </Col>
              <Col
                xs="12"
                sm="6"
                md="4"
                className="text-sm-right text-md-center d-none d-sm-block"
              >
                <Whisper
                  speaker={
                    <Tooltip>Quantity of meals you have in stock</Tooltip>
                  }
                  placement="autoVertical"
                  delayOpen={400}
                  trigger="hover"
                >
                  <h4 className="mb-0 cursor-pointer">
                    Inventory
                    <i
                      className="mdi mdi-information-outline pl-1"
                      style={{ position: "relative", top: 1 }}
                    ></i>
                  </h4>
                </Whisper>
              </Col>
            </Row>
          </div>
        </CardHeader>
      </>
    );
  }

  inventoryList(type, onMenu) {
    return (
      <div>
        {this.state.loadingMeals || this.state.loadingStore ? (
          <>
            <div className="text-center px-3 py-4" style={{}}>
              <Spinner color="medium" size=""></Spinner>
            </div>
          </>
        ) : null}
        <div>
          {!this.filterStockedMeals(
            this.state.store,
            type,
            this.state.meals,
            this.state.inventory,
            onMenu
          )?.length &&
          !this.state.loadingMeals &&
          this.state.loadingStore ? (
            <div className="px-3 py-2">
              <Row>
                <Col>No meals available to stock into inventory.</Col>
              </Row>
            </div>
          ) : null}

          {this.filterStockedMeals(
            this.state.store,
            type,
            this.state.meals,
            this.state.inventory,
            onMenu
          )?.map((c, i) => (
            <div
              className={`px-3 py-2 ${
                this.isRetiringMeal(this.state.store, c) ||
                this.isOffMenuMeal(this.state.store, c)
                  ? "bg-primary-ultralight"
                  : "bg-superlight--hover"
              } cursor-pointer border-bottom`}
              key={i}
            >
              {this.inventoryRow(c)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title={
              <>
                Inventory
                <br />
                <small className="text-muted" style={{ fontSize: 14 }}>
                  {this.state.loadingMeals || this.state.loadingStore ? (
                    "Fetching Inventory..."
                  ) : (
                    <>
                      {this.state.inventory?.createdAt ? (
                        <>
                          <Whisper
                            speaker={
                              <Tooltip>{this.state.inventory?.name}</Tooltip>
                            }
                            placement="auto"
                            trigger="hover"
                            delayOpen={400}
                          >
                            <div className="d-inline-block cursor-pointer">
                              <i
                                className="mdi mdi-information-outline pr-1"
                                style={{ position: "relative", top: 1 }}
                              ></i>
                              Last Reported{" "}
                              {moment(this.state.inventory?.createdAt).format(
                                "MM/DD/YY h:mma"
                              )}
                            </div>
                          </Whisper>
                        </>
                      ) : (
                        "Not Yet Reported"
                      )}
                    </>
                  )}
                </small>
                {this.state.manualAudit ? (
                  <>
                    <br />
                    <div
                      className="text-muted font-weight-normal"
                      style={{ fontSize: 14 }}
                    >
                      Last Manual Audit:{" "}
                      {moment(this.state.manualAudit?.createdAt).format(
                        "MM/DD/YY h:mma"
                      )}
                    </div>
                  </>
                ) : null}
              </>
            }
            showBackButton={false}
            secondaryAction={
              this.state.modified
                ? {
                    name: <>Cancel</>,
                    onClick: () => {
                      this.setState({
                        inventory: JSON.parse(
                          JSON.stringify(
                            this.state.originalInventory
                              ? this.state.originalInventory
                              : {}
                          )
                        ),
                        modified: false,
                      });
                    },
                  }
                : null
            }
            primaryAction={
              this.state.modified
                ? {
                    disabled: this.state.submitting,
                    name: (
                      <>
                        {this.state.submitting ? (
                          <>
                            <Spinner size="sm" />
                          </>
                        ) : (
                          <>Save</>
                        )}
                      </>
                    ),
                    onClick: () => {
                      this.save();
                    },
                  }
                : null
            }
          />

          <Card className="mb-4 border shadow">
            {this.header("Lifestyle Meals")}
            <CardBody className="p-0">
              {this.inventoryList("Lifestyle", true)}
            </CardBody>

            <CardFooter
              className={`cursor-pointer p-3 ${
                this.state.viewOffMenuLifestyle
                  ? "bg-lighter text-white"
                  : "bg-superlight--hover"
              } border-0 ${
                this.state.loadingStore || this.state.loadingMeals
                  ? "d-none"
                  : ""
              }`}
              onClick={() => {
                this.setState({
                  viewOffMenuLifestyle: !this.state.viewOffMenuLifestyle,
                });
              }}
            >
              <Row className="align-items-center">
                <Col xs="">
                  <h4 className="m-0">View All Off-Menu Meals</h4>
                </Col>
                <Col xs="auto">
                  <h3 className="m-0">
                    {this.state.viewOffMenuLifestyle ? (
                      <i className="mdi mdi-chevron-down"></i>
                    ) : (
                      <i className="mdi mdi-chevron-right"></i>
                    )}
                  </h3>
                </Col>
              </Row>
            </CardFooter>
            <Collapse isOpen={this.state.viewOffMenuLifestyle}>
              <CardBody className="p-0 border-top">
                {this.inventoryList("Lifestyle", false)}
              </CardBody>
            </Collapse>
          </Card>
          <Card className="mb-4 border shadow">
            {this.header("Athlete Meals")}
            <CardBody className="p-0">
              {this.inventoryList("Athlete", true)}
            </CardBody>
            <CardFooter
              className={`cursor-pointer p-3 ${
                this.state.viewOffMenuAthlete
                  ? "bg-lighter text-white"
                  : "bg-superlight--hover"
              } border-0`}
              onClick={() => {
                this.setState({
                  viewOffMenuAthlete: !this.state.viewOffMenuAthlete,
                });
              }}
            >
              <Row className="align-items-center">
                <Col xs="">
                  <h4 className="m-0">View All Off-Menu Meals</h4>
                </Col>
                <Col xs="auto">
                  <h3 className="m-0">
                    {this.state.viewOffMenuAthlete ? (
                      <i className="mdi mdi-chevron-down"></i>
                    ) : (
                      <i className="mdi mdi-chevron-right"></i>
                    )}
                  </h3>
                </Col>
              </Row>
            </CardFooter>
            <Collapse isOpen={this.state.viewOffMenuAthlete}>
              <CardBody className="p-0 border-top">
                {this.inventoryList("Athlete", false)}
              </CardBody>
            </Collapse>
          </Card>
          <Card className="mb-4 border shadow">
            {this.header("Lean Cheats")}
            <CardBody className="p-0">
              {this.inventoryList("Lean Cheats", true)}
            </CardBody>
            <CardFooter
              className={`cursor-pointer p-3 ${
                this.state.viewOffMenuCheats
                  ? "bg-lighter text-white"
                  : "bg-superlight--hover"
              } border-0`}
              onClick={() => {
                this.setState({
                  viewOffMenuCheats: !this.state.viewOffMenuCheats,
                });
              }}
            >
              <Row className="align-items-center">
                <Col xs="">
                  <h4 className="m-0">View All Off-Menu Lean Cheats</h4>
                </Col>
                <Col xs="auto">
                  <h3 className="m-0">
                    {this.state.viewOffMenuCheats ? (
                      <i className="mdi mdi-chevron-down"></i>
                    ) : (
                      <i className="mdi mdi-chevron-right"></i>
                    )}
                  </h3>
                </Col>
              </Row>
            </CardFooter>
            <Collapse isOpen={this.state.viewOffMenuCheats}>
              <CardBody className="p-0 border-top">
                {this.inventoryList("Lean Cheats", false)}
              </CardBody>
            </Collapse>
          </Card>
        </Container>
      </>
    );
  }
}

export default StorefrontMealInventoryView;
