import React from "react";
import { Link, withRouter } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Modal,
  Row,
  Col,
} from "reactstrap";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import API from "lib/API";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import User from "lib/Model/User";
import Badge from "reactstrap/lib/Badge";
import moment from "moment";
import NSOUtils from "lib/NSOUtils";
import _ from "underscore";
import { Popover, Whisper } from "rsuite";
import { ProgressBar } from "@tremor/react";

class StoreRow extends React.Component {
  state = {
    showSecret: false,
    name: "",
    description: "",
    key: "",
    secret: "",
    modify: false,
  };

  view(id) {
    this.props.history.push("/frandev/stores/details/" + id + "/about");
  }

  isComplete(item) {
    let nso = this.props.store?.nso;

    if (nso?.[item?._id]?.completed) {
      return true;
    }

    return false;
  }

  calculateSectionCompletion(section) {
    let actions = section?.actions;

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    return (completed.length / actions.length) * 100;
  }

  getSections(template) {
    if (!template) {
      return [];
    }

    let sections = _.filter(_.keys(template), (key) => {
      return (
        key != "_id" &&
        key != "recordType" &&
        key != "createdAt" &&
        key != "modifiedAt" &&
        !key?.includes("items_") &&
        !key?.includes("opening_")
      );
    });

    sections = _.map(sections, (section) => {
      let s = template[section];

      s.key = section;

      return s;
    });

    sections = _.sortBy(sections, "order");

    if (!sections?.length) {
      return 0;
    }

    return sections;
  }

  calculateTotalCompletion() {
    let template = this.props.nsoTemplate ? this.props.nsoTemplate : {};

    let sections = this.getSections(template);

    let actions = [];

    for (let i = 0; i < sections.length; i++) {
      actions = actions.concat(sections[i].actions);
    }

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    return (completed?.length / actions?.length) * 100;
  }

  getCompletionColor(completion) {
    if (completion == 100) {
      return "emerald";
    }

    if (completion >= 75) {
      return "green";
    }

    if (completion >= 50) {
      return "yellow";
    }

    if (completion >= 49) {
      return "orange";
    }

    return "red";
  }

  render() {
    return (
      <>
        <tr
          className="cursor-pointer"
          onClick={() => this.view(this.props.store._id)}
        >
          <td style={{ whiteSpace: "pre-wrap" }}>
            <h4 className="mb-0">
              {this.props.store.name?.replace("Project LeanNation", "PLN")}
            </h4>
            <div>
              <small className="text-dark" style={{ fontSize: 13 }}>
                {this.props?.store?.supportLevel
                  ? this.props.store?.supportLevel
                      ?.replace("Level", "")
                      ?.trim() + " Level"
                  : ""}
              </small>
            </div>
          </td>
          <td className="text-dark">{this.props?.store?.nsoStage ?? "--"}</td>

          <td className="">
            {this.props.store?.type == "retail" ? (
              <small
                className="m-0 font-weight-400 text-dark"
                style={{ fontSize: "14px" }}
              >
                {this.props.store?.location?.address?.address_1}&nbsp;
                {this.props.store?.location?.address?.address_2
                  ? this.props.store.address_2
                  : ""}
                {this.props.store?.location?.address?.address_1 && <br></br>}
                {this.props.store?.location?.address?.city},&nbsp;
                {this.props.store?.location?.address?.state}{" "}
                {this.props.store?.location?.address?.zip}
              </small>
            ) : (
              <p style={{ fontSize: "14px" }} className="m-0">
                No Retail Store
              </p>
            )}
          </td>

          <td className="text-dark">
            {this.props.store?.operations?.storeOpening?.prelaunchStartDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(
                    this.props.store?.operations?.storeOpening
                      ?.prelaunchStartDate
                  ).format("MM/DD/YYYY")}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="text-dark">
            {this.props.store?.onSiteTrainingDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(this.props.store?.onSiteTrainingDate).format(
                    "MM/DD/YYYY"
                  )}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>

          <td className="text-dark">
            {this.props.store?.operations?.storeOpening?.firstBillDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(
                    this.props.store?.operations?.storeOpening?.firstBillDate
                  ).format("MM/DD/YYYY")}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="text-dark">
            {this.props.store?.pricingTier ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {this.props.store?.pricingTier}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="text-dark">
            {this.props.store?.successVisitScore !== null &&
            this.props.store?.successVisitScore !== undefined &&
            this.props.store?.successVisitScore !== "" ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {this.props.store?.successVisitScore}%
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td className="text-dark">
            {this.props.store?.lastVisitDate ? (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  {moment(this.props.store?.lastVisitDate).format("MM/DD/YYYY")}
                </small>
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
          <td style={{ minWidth: 250 }} className="">
            {(!this.props.store?.operations?.storeOpening?.firstBillDate ||
              moment().isBefore(
                moment(
                  this.props.store?.operations?.storeOpening?.firstBillDate
                ).add(60, "days")
              )) &&
            this.props.nsoTemplate ? (
              <div>
                <Whisper
                  trigger={"hover"}
                  placement="autoVertical"
                  preventOverflow={true}
                  delayOpen={400}
                  speaker={
                    <Popover className="dropdown-popover" full>
                      <div className="p-2 border-bottom">
                        <h4 className="m-0 text-dark">NSO Completion</h4>
                      </div>
                      {this.getSections(this.props.nsoTemplate).map(
                        (section, i) => (
                          <div
                            className={`p-2 ${
                              i != this.getSections()?.length - 1 &&
                              "border-bottom"
                            }`}
                            key={i}
                          >
                            <Row className="align-items-center">
                              <Col xs="">
                                <p
                                  className="m-0 small"
                                  style={{ fontSize: 14, lineHeight: 1.2 }}
                                >
                                  {section.name}:
                                </p>
                              </Col>
                              <Col xs="auto">
                                <p
                                  className="m-0 small"
                                  style={{ fontSize: 14, lineHeight: 1.2 }}
                                >
                                  {parseInt(
                                    this.calculateSectionCompletion(section)
                                  )}
                                  %
                                </p>
                              </Col>
                              <Col xs="12">
                                <ProgressBar
                                  percentageValue={this.calculateSectionCompletion(
                                    section
                                  )}
                                  color={this.getCompletionColor(
                                    this.calculateSectionCompletion(section)
                                  )}
                                  marginTop="mt-2"
                                />
                              </Col>
                            </Row>
                          </div>
                        )
                      )}
                    </Popover>
                  }
                >
                  <div className="d-block py-1">
                    <Row className="align-items-center">
                      <Col xs="">
                        <p
                          className="m-0 small"
                          style={{ fontSize: 14, lineHeight: 1.2 }}
                        >
                          NSO Completion:
                        </p>
                      </Col>
                      <Col xs="auto">
                        <p
                          className="m-0 small"
                          style={{ fontSize: 14, lineHeight: 1.2 }}
                        >
                          {parseInt(this.calculateTotalCompletion())}%
                        </p>
                      </Col>
                      <Col xs="12">
                        <ProgressBar
                          percentageValue={this.calculateTotalCompletion()}
                          color={this.getCompletionColor(
                            this.calculateTotalCompletion()
                          )}
                          marginTop="mt-2"
                        />
                      </Col>
                    </Row>
                  </div>
                </Whisper>
              </div>
            ) : (
              <div className="text-center">
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>

          <td className="text-dark">
            {this.props.store?.franchisePartners?.length ? (
              <div>
                {this.props.store?.franchisePartners?.map((partner, i) => (
                  <div key={i}>
                    <small
                      className="m-0 font-weight-400"
                      style={{ fontSize: "14px" }}
                    >
                      {partner?.name}
                    </small>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <small
                  className="m-0 font-weight-400"
                  style={{ fontSize: "14px" }}
                >
                  --
                </small>
              </div>
            )}
          </td>
        </tr>
      </>
    );
  }
}

export default withRouter(StoreRow);
