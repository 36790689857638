import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontCreateStaffMemberDrawer from "components/Drawers/StorefrontCreateStaffMemberDrawer";
import StorefrontModifyStaffMemberDrawer from "components/Drawers/StorefrontModifyStaffMemberDrawer";
import swal from "sweetalert";
import { Capacitor } from "@capacitor/core";
import LinkUtils from "lib/LinkUtils";

class BrandCentralView extends React.Component {
  state = {
    wordmarks: [
      {
        name: "Primary Wordmark",
        description: "This wordmark should be used in most cases.",
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-With-Padding/Colored/PLN-Wordmark-With-Padding-Colored",
      },
      {
        name: "Inverted Wordmark",
        description: "Used when placed against a darker background.",
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-With-Padding/Colored-Inverted/PLN-Wordmark-With-Padding-Colored-Inverted",
      },
      {
        name: "Black Wordmark",
        description: "For formal use cases against white background.",
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-With-Padding/Black/PLN-Wordmark-With-Padding-Black",
      },
      {
        name: "White Wordmark",
        description: "For formal use cases against dark background.",
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-With-Padding/White/PLN-Wordmark-With-Padding-White",
      },
    ],
    wordmarksNoPadding: [
      {
        name: "Primary Wordmark",
        description: "This wordmark should be used in most cases.",
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-No-Padding/Colored/PLN-Wordmark-No-Padding-Colored",
      },
      {
        name: "Inverted Wordmark",
        description: "Used when placed against a darker background.",
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-No-Padding/Colored-Inverted/PLN-Wordmark-No-Padding-Colored-Inverted",
      },
      {
        name: "Black Wordmark",
        description: "For formal use cases against white background.",
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-No-Padding/Black/PLN-Wordmark-No-Padding-Black",
      },
      {
        name: "White Wordmark",
        description: "For formal use cases against dark background.",
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Wordmark-No-Padding/White/PLN-Wordmark-No-Padding-White",
      },
    ],
    symbols: [
      {
        name: "Primary Symbol",
        description: `Used in cases where the Primary Wordmark is too large.`,
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-With-Padding/Colored/PLN-Symbol-With-Padding-Colored",
      },
      {
        name: "Inverted Symbol",
        description: `Used in cases where the Inverted Wordmark is too large.`,
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-With-Padding/Colored-Inverted/PLN-Symbol-With-Padding-Colored-Inverted",
      },
      {
        name: "Black Symbol",
        description: "For formal use cases against white background.",
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-With-Padding/Black/PLN-Symbol-With-Padding-Black",
      },
      {
        name: "White Symbol",
        description: "For formal use cases against dark background.",
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-With-Padding/White/PLN-Symbol-With-Padding-White",
      },
    ],
    symbolsNoPadding: [
      {
        name: "Primary Symbol",
        description: `Used in cases where the Primary Wordmark is too large.`,
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-No-Padding/Colored/PLN-Symbol-No-Padding-Colored",
      },
      {
        name: "Inverted Symbol",
        description: `Used in cases where the Inverted Wordmark is too large.`,
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-No-Padding/Colored-Inverted/PLN-Symbol-No-Padding-Colored-Inverted",
      },
      {
        name: "Black Symbol",
        description: "For formal use cases against white background.",
        bg: "bg-white",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-No-Padding/Black/PLN-Symbol-No-Padding-Black",
      },
      {
        name: "White Symbol",
        description: "For formal use cases against dark background.",
        bg: "bg-secondary",
        baseCDNPath:
          "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets/Symbol-No-Padding/White/PLN-Symbol-No-Padding-White",
      },
    ],
  };

  openFile(url) {
    LinkUtils.openInNewTab(url);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  copyToClipboard(val) {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(val);
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div
          className="bg-white border-bottom px-4 py-4 py-md-5 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="auto">
              <h1 className="display-1 text-primary m-0">
                <i className="mdi mdi-star-circle"></i>
              </h1>
            </Col>
            <Col xs="">
              <h1 className="m-0 display-3 text-dark">
                Visual Guides &amp; Assets
              </h1>
              <p className="m-0 text-dark font-weight-500">
                Quick access to logos, symbols, brand colors, and other
                resources.
              </p>
            </Col>
          </Row>
        </div>
        <Container fluid>
          {/* Table */}
          <div className="mb-4">
            <div className="mb-6">
              {/**/}
              <Row className="align-items-center mb-0">
                <Col xs="">
                  <h1 className="text-dark mb-0">Colors</h1>
                </Col>
              </Row>
              <hr className="my-4"></hr>

              <Row className="align-items-center">
                <Col xs="12" sm="12" md="6" lg="4">
                  <h2 className="mb-4 text-dark">Orange</h2>
                  <Card className="border">
                    <CardBody
                      style={{
                        height: 200,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                      }}
                      className="bg-primary"
                    ></CardBody>
                    <CardFooter className="p-0">
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              HEX
                            </h5>
                            <h2 className="m-0 text-dark">#FB6340</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("#FB6340");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              RGB
                            </h5>
                            <h2 className="m-0 text-dark">rgb(251,99,64)</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("rgb(251,99,64)");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              CMYK
                            </h5>
                            <h2 className="m-0 text-dark">cmyk(0,78,81,0)</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("cmyk(0,78,81,0)");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" className="mt-3 mt-md-0">
                  <h2 className="mb-4 text-dark">Secondary</h2>
                  <Card className="border">
                    <CardBody
                      style={{
                        height: 200,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                      }}
                      className="bg-dark"
                    ></CardBody>
                    <CardFooter className="p-0">
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              HEX
                            </h5>
                            <h2 className="m-0 text-dark">#111411</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("#111411");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              RGB
                            </h5>
                            <h2 className="m-0 text-dark">rgb(17,20,17)</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("rgb(17,20,17)");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              CMYK
                            </h5>
                            <h2 className="m-0 text-dark">cmyk(0,0,0,100)</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("cmyk(0,0,0,100)");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
                <Col xs="12" sm="12" md="6" lg="4" className="mt-3 mt-lg-0">
                  <h2 className="mb-4 text-dark">Green</h2>
                  <Card className="border">
                    <CardBody
                      style={{
                        height: 200,
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        background: "#55CE00",
                      }}
                    ></CardBody>
                    <CardFooter className="p-0">
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              HEX
                            </h5>
                            <h2 className="m-0 text-dark">#55CE00</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("#55CE00");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              RGB
                            </h5>
                            <h2 className="m-0 text-dark">rgb(85,206,0)</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("rgb(85,206,0)");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 border-bottom">
                        <Row className="align-items-center">
                          <Col xs="">
                            <h5 className="text-uppercase text-muted mb-0">
                              CMYK
                            </h5>
                            <h2 className="m-0 text-dark">cmyk(58,1,100,0)</h2>
                          </Col>
                          <Col xs="auto">
                            <Button
                              size="sm"
                              outline
                              dark
                              className="btn-icon-only border-0"
                              onClick={() => {
                                this.copyToClipboard("cmyk(58,1,100,0)");
                              }}
                            >
                              <i className="mdi mdi-content-copy"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </div>

            {/**/}
            <Row className="align-items-center mb-0">
              <Col xs="">
                <h1 className="text-dark mb-0">Typography</h1>
              </Col>
            </Row>
            <hr className="my-4"></hr>

            <div className="mb-4">
              <Row className="align-items-center">
                <Col xs="">
                  <h2 className="mb-0 text-dark">Primary Typeface</h2>
                  <p className="mb-0">
                    Karla is a clean, modern, sans-serif typeface that works
                    well for display, copy, and body text.
                  </p>
                </Col>
                <Col xs="auto">
                  <Button
                    color="dark"
                    outline
                    onClick={() => {
                      this.openFile(
                        "https://fonts.google.com/download?family=Karla"
                      );
                    }}
                    className="btn-icon-only"
                  >
                    <i className="mdi mdi-download"></i>
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="pb-4">
              <Card className="mb-4">
                <CardHeader className="p-3">
                  <h3 className="m-0 text-dark">Usage Examples</h3>
                </CardHeader>
                <CardBody className={`p-3 text-left`}>
                  <h2 className="font-weight-normal text-dark">
                    Karla Regular
                  </h2>
                  <h2 className="font-weight-normal font-italic text-dark mb-3">
                    Karla Italic
                  </h2>
                  <h2 className="font-weight-500 text-dark">Karla Medium</h2>
                  <h2 className="font-weight-500 text-dark font-italic mb-3">
                    Karla Medium Italic
                  </h2>
                  <h2 className="font-weight-700 text-dark">Karla Bold</h2>
                  <h2 className="font-weight-700 text-dark font-italic mb-3">
                    Karla Bold Italic
                  </h2>
                  <h2 className="font-weight-800 text-dark">Karla ExtraBold</h2>
                  <h2 className="font-weight-800 text-dark font-italic mb-0">
                    Karla ExtraBold Italic
                  </h2>
                </CardBody>
              </Card>
            </div>
            <div className="mb-4">
              <h2 className="mb-0 text-dark">Websafe Alternative</h2>
              <p className="mb-4">
                When Karla is unavailable Arial Regular is a websafe and
                universally accessible font.
              </p>
            </div>
            <Card className="mb-6">
              <CardHeader className="p-3">
                <h3 className="m-0 text-dark">Usage Examples</h3>
              </CardHeader>
              <CardBody className={`p-3 text-left`}>
                <h2
                  className="font-weight-normal text-dark"
                  style={{ fontFamily: "Arial" }}
                >
                  Arial Regular
                </h2>
                <h2
                  style={{ fontFamily: "Arial" }}
                  className="font-weight-normal font-italic text-dark mb-3"
                >
                  Arial Italic
                </h2>

                <h2
                  style={{ fontFamily: "Arial" }}
                  className="font-weight-700 text-dark"
                >
                  Arial Bold
                </h2>
                <h2
                  style={{ fontFamily: "Arial" }}
                  className="font-weight-700 text-dark font-italic mb-0"
                >
                  Arial Bold Italic
                </h2>
              </CardBody>
            </Card>
            <Row className="align-items-center mb-0">
              <Col xs="">
                <h1 className="text-dark mb-0">Logo Assets</h1>
              </Col>
              <Col xs="auto">
                <Button
                  color="dark"
                  outline
                  onClick={() => {
                    this.openFile(
                      "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN-Assets.zip"
                    );
                  }}
                  className="btn-icon-only"
                >
                  <i className="mdi mdi-download"></i>
                </Button>
              </Col>
            </Row>
            <hr className="my-4"></hr>
            <div className="mb-4">
              <h2 className="mb-0 text-dark">Wordmarks with Border Padding</h2>
              <p className="mb-4">
                Our larger logo containing the full spelling of Project
                LeanNation, pre-formatted with the proper border padding in each
                image.
              </p>
              <Row>
                {this.state.wordmarks?.map((logo, i) => (
                  <Col xs="12" md="6" lg="6" key={i} className="mb-4">
                    <Card>
                      <CardHeader className="p-3">
                        <h3 className="m-0 text-dark">{logo?.name}</h3>
                        <p className="small m-0">{logo.description}</p>
                      </CardHeader>
                      <CardBody className={`p-0 text-center ${logo?.bg}`}>
                        <img
                          src={`${logo?.baseCDNPath}.svg`}
                          style={{ width: "100%", maxWidth: 400 }}
                        ></img>
                      </CardBody>
                      <CardFooter className="py-2 px-3">
                        <Row>
                          <Col xs="">
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".jpg");
                              }}
                            >
                              JPEG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".png");
                              }}
                            >
                              PNG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".svg");
                              }}
                            >
                              SVG VECTOR
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".pdf");
                              }}
                            >
                              PDF
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".webp");
                              }}
                            >
                              WEBP
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="mb-4">
              <h2 className="mb-0 text-dark">
                Wordmarks without Border Padding
              </h2>
              <p className="mb-4">
                Our larger logo containing the full spelling of Project
                LeanNation, without any border padding in each image.
              </p>
              <Row>
                {this.state.wordmarksNoPadding?.map((logo, i) => (
                  <Col xs="12" md="6" lg="6" key={i} className="mb-4">
                    <Card>
                      <CardHeader className="p-3">
                        <h3 className="m-0 text-dark">{logo?.name}</h3>
                        <p className="small m-0">{logo.description}</p>
                      </CardHeader>
                      <CardBody className={`p-5 text-center ${logo?.bg}`}>
                        <img
                          src={`${logo?.baseCDNPath}.svg`}
                          style={{ width: "100%", maxWidth: 400 }}
                        ></img>
                      </CardBody>
                      <CardFooter className="py-2 px-3">
                        <Row>
                          <Col xs="">
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".jpg");
                              }}
                            >
                              JPEG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".png");
                              }}
                            >
                              PNG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".svg");
                              }}
                            >
                              SVG VECTOR
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".pdf");
                              }}
                            >
                              PDF
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".webp");
                              }}
                            >
                              WEBP
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="mb-4">
              <h2 className="mb-0 text-dark">Symbols with Border Padding</h2>
              <p className="mb-4">
                Our condensed logo containing the PLN lettering in a circle,
                pre-formatted with the proper border padding in each image.
              </p>
              <Row>
                {this.state.symbols?.map((logo, i) => (
                  <Col xs="12" md="6" lg="6" key={i} className="mb-4">
                    <Card>
                      <CardHeader className="p-3">
                        <h3 className="m-0 text-dark">{logo?.name}</h3>
                        <p className="small m-0">{logo.description}</p>
                      </CardHeader>
                      <CardBody className={`p-0 text-center ${logo?.bg}`}>
                        <img
                          src={`${logo?.baseCDNPath}.svg`}
                          style={{ width: "100%", maxWidth: 250 }}
                        ></img>
                      </CardBody>
                      <CardFooter className="px-3 py-2">
                        <Row>
                          <Col xs="">
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".jpg");
                              }}
                            >
                              JPEG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".png");
                              }}
                            >
                              PNG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".svg");
                              }}
                            >
                              SVG VECTOR
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".pdf");
                              }}
                            >
                              PDF
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".webp");
                              }}
                            >
                              WEBP
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
            <div className="mb-6">
              <h2 className="mb-0 text-dark">Symbols without Border Padding</h2>
              <p className="mb-4">
                Our condensed logo containing the PLN lettering in a circle,
                without any border padding in each image.
              </p>
              <Row>
                {this.state.symbolsNoPadding?.map((logo, i) => (
                  <Col xs="12" md="6" lg="6" key={i} className="mb-4">
                    <Card>
                      <CardHeader className="p-3">
                        <h3 className="m-0 text-dark">{logo?.name}</h3>
                        <p className="small m-0">{logo.description}</p>
                      </CardHeader>
                      <CardBody className={`p-5 text-center ${logo?.bg}`}>
                        <img
                          src={`${logo?.baseCDNPath}.svg`}
                          style={{ width: "100%", maxWidth: 200 }}
                        ></img>
                      </CardBody>
                      <CardFooter className="px-3 py-2">
                        <Row>
                          <Col xs="">
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".jpg");
                              }}
                            >
                              JPEG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".png");
                              }}
                            >
                              PNG
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".svg");
                              }}
                            >
                              SVG VECTOR
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".pdf");
                              }}
                            >
                              PDF
                            </Button>
                            <Button
                              className="my-2"
                              size="sm"
                              color="dark"
                              outline
                              onClick={() => {
                                this.openFile(logo?.baseCDNPath + ".webp");
                              }}
                            >
                              WEBP
                            </Button>
                          </Col>
                        </Row>
                      </CardFooter>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>

            <div className="mb-4">
              {/**/}
              <Row className="align-items-center mb-0">
                <Col xs="">
                  <h1 className="text-dark mb-0">Apparel Assets</h1>
                </Col>
              </Row>
              <hr className="my-4"></hr>

              <Card className="border">
                <CardHeader className="p-3">
                  <h3 className="m-0 text-dark">PLN Flag T-Shirt Design</h3>
                </CardHeader>
                <CardBody className="p-5 text-center">
                  <img
                    src="https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN+Flag+T-Shirt+Design+Black.svg"
                    alt="T-shirt design"
                    style={{ width: "100%", maxWidth: 400, margin: "auto" }}
                  ></img>
                </CardBody>
                <CardFooter className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase text-muted mb-2">
                          Black Design
                        </h5>
                      </Col>
                      <Col xs="12">
                        <Button
                          size="sm"
                          outline
                          dark
                          className=""
                          onClick={() => {
                            this.openFile(
                              "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN+Flag+T-Shirt+Design+Black.png"
                            );
                          }}
                        >
                          PNG
                        </Button>
                        <Button
                          size="sm"
                          outline
                          dark
                          className=""
                          onClick={() => {
                            this.openFile(
                              "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN+Flag+T-Shirt+Design+Black.pdf"
                            );
                          }}
                        >
                          PDF
                        </Button>
                        <Button
                          size="sm"
                          outline
                          dark
                          className=""
                          onClick={() => {
                            this.openFile(
                              "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN+Flag+T-Shirt+Design+Black.svg"
                            );
                          }}
                        >
                          SVG VECTOR
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <h5 className="text-uppercase text-muted mb-2">
                          White Design
                        </h5>
                      </Col>
                      <Col xs="12">
                        <Button
                          size="sm"
                          outline
                          dark
                          className=""
                          onClick={() => {
                            this.openFile(
                              "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN+Flag+T-Shirt+Design+White.png"
                            );
                          }}
                        >
                          PNG
                        </Button>

                        <Button
                          size="sm"
                          outline
                          dark
                          className=""
                          onClick={() => {
                            this.openFile(
                              "https://s3.amazonaws.com/cdn.projectleannation.com/assets/PLN+Flag+T-Shirt+Design+White.svg"
                            );
                          }}
                        >
                          SVG VECTOR
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default BrandCentralView;
