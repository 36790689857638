import React from "react";
// react component that copies the given text inside your clipboard

import { DateRangePicker } from "react-date-range";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Spinner,
  Collapse,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex } from "underscore";
import StoreRow from "components/StoreRow";
import CreateStoreModal from "components/CreateStoreModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import moment from "moment";
import _ from "underscore";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";

class StoreGrowthDashboard extends React.Component {
  state = {};

  getUser() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = this.getUser();

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  componentDidMount() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    console.log(user);

    if (!user?.globalPermissions?.length) {
      this.props.history.push("/reports");

      return;
    }
  }

  calculateMembers() {
    if (
      isNaN(this.state.activeMembers) ||
      isNaN(this.state.churnRate) ||
      isNaN(this.state.growthRate)
    ) {
      this.setState({ newMembers: "" }, () => {
        this.calculateLeads();
      });

      return;
    }

    let activeMembers = parseFloat(this.state.activeMembers);

    let churnRate = parseFloat(this.state.churnRate) / 100;

    let growthRate = parseFloat(this.state.growthRate) / 100;

    let referralRate = this.state.referralRate
      ? parseFloat(this.state.referralRate) / 100
      : 0;

    let newMembers = Math.ceil(
      activeMembers * churnRate + activeMembers * growthRate
    );

    newMembers = newMembers * (1 - referralRate);

    newMembers = Math.ceil(newMembers);

    this.setState({ newMembers: newMembers }, () => {
      this.calculateLeads();
    });
  }

  calculateLeads() {
    if (isNaN(this.state.newMembers) || isNaN(this.state.conversionRate)) {
      this.setState({ newLeads: "" });

      return;
    }

    let newMembers = parseFloat(this.state.newMembers);
    let conversionRate = parseFloat(this.state.conversionRate) / 100;

    let newLeads = Math.ceil(newMembers / conversionRate);

    this.setState({ newLeads: newLeads }, () => {
      this.calculateLeadChange();
    });
  }

  calculateLeadChange() {
    if (isNaN(this.state.newLeads) || isNaN(this.state.historicalLeads)) {
      this.setState({ leadChange: "" });

      return;
    }

    let newLeads = parseFloat(this.state.newLeads);
    let historicalLeads = parseFloat(this.state.historicalLeads);

    let leadChange = (newLeads - historicalLeads) / historicalLeads;

    let leadChangeString = `${leadChange > 0 ? "+" : ""}${(
      leadChange * 100
    ).toFixed(1)}%`;

    this.setState({ leadChange: leadChangeString });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <iframe
            src="https://pln.metabaseapp.com/public/dashboard/c44892dc-6145-4ff1-a5cc-e903a365c0c1"
            frameborder="0"
            width="100%"
            height="auto"
            style={{ height: "600px" }}
            allowtransparency
          ></iframe>
          <div className="mt-4">
            <h1>Growth Calculator</h1>
            <p>
              Using the figures above, enter each figure into the calculator
              below to determine the number of leads required to achieve the
              targeted monthly growth goal.
            </p>

            <hr className="my-3"></hr>
            <h2>Member Growth</h2>
            <Row className="align-items-center mt-3">
              <Col xs="12" sm="auto" className="mb-3">
                <h5 style={{ width: 140 }}>Avg. Active Members</h5>
                <Input
                  style={{ width: 140 }}
                  type="number"
                  bsSize="sm"
                  value={this.state.activeMembers}
                  onChange={(e) =>
                    this.setState({ activeMembers: e.target.value }, () => {
                      this.calculateMembers();
                    })
                  }
                  placeholder="Average Active Members"
                />
              </Col>
              <Col xs="12" sm="auto" className="pt-3 px-0 text-dark mb-3">
                x (
              </Col>
              <Col xs="12" sm="auto" className="mb-3">
                <h5 style={{ width: 140 }}>Avg. Member Churn %</h5>
                <Input
                  style={{ width: 140 }}
                  type="number"
                  bsSize="sm"
                  value={this.state.churnRate}
                  onChange={(e) =>
                    this.setState({ churnRate: e.target.value }, () => {
                      this.calculateMembers();
                    })
                  }
                  placeholder="Monthly Member Churn"
                />
              </Col>
              <Col xs="12" sm="auto" className="pt-3 px-0 text-dark mb-3">
                +
              </Col>
              <Col xs="12" sm="auto" className="mb-3">
                <h5>Target Monthly Growth %</h5>
                <Input
                  type="number"
                  bsSize="sm"
                  value={this.state.growthRate}
                  onChange={(e) =>
                    this.setState({ growthRate: e.target.value }, () => {
                      this.calculateMembers();
                    })
                  }
                  placeholder="Growth Rate"
                />
              </Col>
              <Col xs="12" sm="auto" className="pt-3 px-0 text-dark mb-3">
                ) x ( 1 -
              </Col>
              <Col xs="12" sm="auto" className="mb-3">
                <h5 style={{ width: 120 }}>Avg. Referral Rate</h5>
                <Input
                  style={{ width: 120 }}
                  type="number"
                  bsSize="sm"
                  value={this.state.referralRate}
                  onChange={(e) =>
                    this.setState({ referralRate: e.target.value }, () => {
                      this.calculateMembers();
                    })
                  }
                  placeholder="Referral%"
                />
              </Col>
              <Col xs="12" sm="auto" className="pt-3 px-0 text-dark mb-3">
                ) =
              </Col>
              <Col xs="12" sm="auto" className="mb-3">
                <h5>New Members Needed</h5>
                <Input
                  type="number"
                  bsSize="sm"
                  value={this.state.newMembers}
                  placeholder="New Members"
                  readOnly={true}
                />
              </Col>
            </Row>
            <hr className="my-3"></hr>
            <h2>Lead Acquisition</h2>
            <div className="mt-4">
              <Row className="align-items-center mt-3">
                <Col xs="12" sm="auto">
                  <h5>New Members Needed</h5>
                  <Input
                    type="number"
                    bsSize="sm"
                    value={this.state.newMembers}
                    placeholder="New Members"
                    readOnly={true}
                  />
                </Col>
                <Col xs="12" sm="auto" className="pt-3 px-0 text-dark">
                  /
                </Col>
                <Col xs="12" sm="auto">
                  <h5>Average Conversion Rate</h5>
                  <Input
                    type="number"
                    bsSize="sm"
                    value={this.state.conversionRate}
                    onChange={(e) =>
                      this.setState({ conversionRate: e.target.value }, () => {
                        this.calculateLeads();
                      })
                    }
                    placeholder="Conversion Rate"
                  />
                </Col>

                <Col xs="12" sm="auto" className="pt-3 px-0 text-dark">
                  =
                </Col>
                <Col xs="12" sm="auto">
                  <h5>New Leads Needed</h5>
                  <Input
                    type="number"
                    bsSize="sm"
                    value={this.state.newLeads}
                    placeholder="New Leads"
                    readOnly={true}
                  />
                </Col>
                <Col xs="12" sm="auto" className="pt-3 px-0 text-dark">
                  % 4
                </Col>
                <Col xs="12" sm="auto">
                  <h5>New Leads Per Week</h5>
                  <Input
                    type="number"
                    bsSize="sm"
                    value={
                      !isNaN(this.state.newLeads)
                        ? Math.ceil(this.state.newLeads / 4)
                        : ""
                    }
                    placeholder="New Leads Per Week"
                    readOnly={true}
                  />
                </Col>
              </Row>
            </div>
            <hr className="my-3"></hr>
            <h2>Lead Acquisition Adjustments</h2>
            <div className="mt-4">
              <Row className="align-items-center mt-3">
                <Col xs="12" sm="auto">
                  <h5>New Leads Needed</h5>
                  <Input
                    type="number"
                    bsSize="sm"
                    value={this.state.newLeads}
                    placeholder="New Leads"
                    readOnly={true}
                  />
                </Col>
                <Col xs="12" sm="auto" className="pt-3 px-0 text-dark">
                  /
                </Col>
                <Col xs="12" sm="auto">
                  <h5>Historical New Leads</h5>
                  <Input
                    type="number"
                    bsSize="sm"
                    value={this.state.historicalLeads}
                    onChange={(e) =>
                      this.setState({ historicalLeads: e.target.value }, () => {
                        this.calculateLeadChange();
                      })
                    }
                    placeholder="Historical New Leads"
                  />
                </Col>

                <Col xs="12" sm="auto" className="pt-3 px-0 text-dark">
                  =
                </Col>
                <Col xs="12" sm="auto">
                  <h5>Change In Lead Acquisition</h5>
                  <Input
                    type="text"
                    bsSize="sm"
                    value={this.state.leadChange}
                    placeholder="Change Needed"
                    readOnly={true}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default StoreGrowthDashboard;
